<template>
  <router-view />
</template>
<script>
export default {
  name: "",
  data: () => ({

  })
}
</script>
